/**
* Created by hansxing on 2017/12/14.
*/
<style lang="scss" scoped>
    .etrip-canvas-bg {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        overflow: hidden;
        width: 2400px;
        background: rgba(0, 0, 0, 0.02);
    }
</style>
<template>

    <div class="etrip-canvas-bg">
        <canvas id="etrip-canvas"></canvas>
    </div>

</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            //定义画布宽高和生成点的个数
            let WIDTH = 2400, HEIGHT = window.innerHeight, POINT = 30;
            let canvas, context;
            let circleArr = [];
            return {
                WIDTH,
                HEIGHT,
                POINT,
                canvas,
                context,
                circleArr

            }
        },
        components: {},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
            this.canvas = document.getElementById('etrip-canvas');
            this.canvas.width = this.WIDTH;
            this.canvas.height = this.HEIGHT;
            this.context = this.canvas.getContext('2d');
            this.context.strokeStyle = 'rgba(0,0,0,0.02)';
            this.context.strokeWidth = 1;
            this.context.fillStyle = 'rgba(0,0,0,0.05)';
            this.init();
            setInterval(() => {
                for (let i = 0; i < this.POINT; i++) {
                    let cir = this.circleArr[i];
                    cir.x += cir.moveX;
                    cir.y += cir.moveY;
                    if (cir.x > this.WIDTH) cir.x = 0;
                    else if (cir.x < 0) cir.x = this.WIDTH;
                    if (cir.y > this.HEIGHT) cir.y = 0;
                    else if (cir.y < 0) cir.y = this.HEIGHT;

                }
                this.draw();
            }, 16);

        },
        //定义事件方法
        methods: {


            //线条：开始xy坐标，结束xy坐标，线条透明度
            Line(x, y, _x, _y, o) {
                let line = {};
                line.beginX = x;
                line.beginY = y;
                line.closeX = _x;
                line.closeY = _y;
                line.o = o;
                return line;
            },
            //点：圆心xy坐标，半径，每帧移动xy的距离
            Circle(x, y, r, moveX, moveY) {
                let circle = {};
                circle.x = x;
                circle.y = y;
                circle.r = r;
                circle.moveX = moveX;
                circle.moveY = moveY;
                return circle;
            },
            //生成max和min之间的随机数
            num(max) {
                let min = arguments[1] || 0;
                return Math.floor(Math.random() * (max - min + 1) + min);
            },
            // 绘制原点
            drawCricle(cxt, x, y, r, moveX, moveY) {
                let circle = this.Circle(x, y, r, moveX, moveY);
                cxt.beginPath();
                cxt.arc(circle.x, circle.y, circle.r, 0, 2 * Math.PI);
                cxt.closePath();
                cxt.fill();
                return circle;
            },
            //绘制线条
            drawLine(cxt, x, y, _x, _y, o) {
                let line = this.Line(x, y, _x, _y, o);
                cxt.beginPath();
                cxt.strokeStyle = 'rgba(0,0,0,' + o + ')';
                cxt.moveTo(line.beginX, line.beginY);
                cxt.lineTo(line.closeX, line.closeY);
                cxt.closePath();
                cxt.stroke();

            },
            //初始化生成原点
            init() {
                this.circleArr = [];
                for (let i = 0; i < this.POINT; i++) {
                    this.circleArr.push(this.drawCricle(this.context, this.num(this.WIDTH), this.num(this.HEIGHT), this.num(15, 2), this.num(10, -10) / 40, this.num(10, -10) / 40));
                }
                this.draw();
            },

            //每帧绘制
            draw() {
                this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
                for (let i = 0; i < this.POINT; i++) {
                    this.drawCricle(this.context, this.circleArr[i].x, this.circleArr[i].y, this.circleArr[i].r);
                }
                for (let i = 0; i < this.POINT; i++) {
                    for (let j = 0; j < this.POINT; j++) {
                        if (i + j < this.POINT) {
                            let A = Math.abs(this.circleArr[i + j].x - this.circleArr[i].x),
                                B = Math.abs(this.circleArr[i + j].y - this.circleArr[i].y);
                            let lineLength = Math.sqrt(A * A + B * B);
                            let C = 1 / lineLength * 7 - 0.009;
                            let lineOpacity = C > 0.03 ? 0.03 : C;
                            if (lineOpacity > 0) {
                                this.drawLine(this.context, this.circleArr[i].x, this.circleArr[i].y, this.circleArr[i + j].x, this.circleArr[i + j].y, lineOpacity);
                            }
                        }
                    }
                }
            }
        },
        //监听模版变量
        watch: {}

    }
</script>

