<style lang="scss" scoped>
    @import "home-bms";
</style>
<template>
    <div>
        <el-main class="home-cont-main eTrip-section-cont" :class="{'home-cont-login':isLogin}">
            <div class="eTrip-home">
                <login-canvas></login-canvas>
                <div class="home-info" v-if="isLogin && userInfo.refType == '1'">
                    <h3>您好，欢迎进入{{title}}</h3>
                    <ul class="home-register-code clear">
                        <li v-for="(item, index) in qrCodeList" :key="index">
                            <div class="code-card" :id="item.id">
                                <div class="code-header">
                                    <img class="code-header_img" :src="item.logo">
                                    <p class="code-header_title">{{item.exclusiveTitle}}</p>
                                </div>
                                <div class="code-company-name">
                                    {{item.companyName}}
                                </div>
                                <div class="code-section" style="background: url(./images/home_code_bg.jpg)">
                                    <div class="code-company-href">
                                        <qr-code-vue :value="item.codeValue" :size="234" level="H"></qr-code-vue>
                                    </div>
                                    <p class="code-distinguish">{{item.codeDistinguish}}</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul class="home-register-code1 clear">
                        <li v-for="(item, index1) in qrCodeListPush" :key="index1">
                            <img :src="item.imgSrcData" style="width: 300px;height: 400px;"/>
                            <div class="code-explain">
                                <p v-for="(item1, index) in item.codeExplain" :key="index">{{item1}}</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="home-cont" v-else>
                    <img :src="logo">
                    <h2 v-if="isLogin">欢迎进入{{title}}</h2>
                </div>
            </div>
        </el-main>
    </div>
</template>

<script type="text/ecmascript-6">
    import config from '../../../config/';
    import loginCanvas from '../../../components/loginCanvas/index.vue'
    import QrCodeVue from 'qrcode.vue'
    import html2canvas from 'html2canvas'

    export default {
        //定义模版数据
        data() {

            return {
                imgSrc: '',
                qrCodeListPush: null,
                // sectionStyle:{background: 'url(' + require('@/assets/images/home_code_bg.jpg') + ')'}

            }
        },
        components: {
            loginCanvas,
            QrCodeVue
        },
        computed: {
            logo() {
                return process.env_project.SYSTEM_NAME === 'zsl' ? require('@/assets/images/logo-zsl.png') :
                    require('@/assets/images/logo_full.png')
            },
            title() {
                return process.env_project.PROJECT_NAME
            },
            isLogin() {
                return this.$store.state.eTripToken
            },
            userInfo() {
                return this.$store.state.loginUserInfo
            },
            codeId() {
                let id = 'etripCode';
                return id
            },
            qrCodeList() {
                let list = [{
                    id: this.codeId,
                    logo: './images/logo_full.png',
                    exclusiveTitle: '司机注册专属码',
                    companyName: this.userInfo.companyName,
                    codeValue: config.protocol + '//' + (config.Debug ? 'driver-h5-emu.900etrip.com' : 'driver-h5.900etrip.com')
                    + '/?orgId=' + this.userInfo.refId + '&' + 'orgName=' + this.userInfo.companyName,
                    codeDistinguish: '微信扫码或长按识别',
                    codeExplain: ['右键复制司机注册专属二维码', '通过微信发送给司机', '司机注册后，将直接归属本公司']
                }]
                return list
            }
        },
        //主件被加载完成
        mounted: function () {
            if (!this.isLogin) {
                this.$store.dispatch('CHANGE_LOGIN_OR_REGISTER', {
                    show: true,
                    type: 0
                });
            }

            if (this.isLogin && this.codeId) {
                this.codeCanvasImg(this.codeId)
            }

        },
        //定义事件方法
        methods: {
            handleLogin() {
                this.$store.dispatch('CHANGE_LOGIN_OR_REGISTER', {
                    show: true,
                    type: 0
                });
            },
            codeCanvasImg(codeCanvas) {
                let codeCanvasId = document.getElementById(codeCanvas);
                let _this = this;
                if (codeCanvasId) {
                    html2canvas(codeCanvasId).then(function (canvas) {
                        let imgUrl = canvas.toDataURL('image/png', 1);
                        _this.qrCodeListPush = [];
                        _this.qrCodeList.forEach(item => {
                            if (item.id === _this.codeId) {
                                item.imgSrcData = imgUrl;
                                _this.qrCodeListPush.push(item);
                            }
                        })
                    });
                }
            }
        },
        //监听模版变量
        watch: {
            isLogin(n) {
                if (n)
                    setTimeout(() => {
                        this.codeCanvasImg(this.codeId)
                    }, 200)
            }
        }

    }
</script>
